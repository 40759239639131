import React, { useEffect, useRef, useState, useReducer } from "react";
import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";


export default function Chamber2_BiasPowerModel({ gasBoxState,IOWrite,setBiasscreen,biasscreen}) {


  const [setpoint,setSetpoint] = useState()
  const modeFunction = async (e) => {
    let val = !e;
    IOWrite('MAIN.DicingChamberBias_Power.Out.RF_ON_Request', val);
    // setMode(val)
    console.log(val)
   }
   const SetpointFunction = async () =>{
    IOWrite('MAIN.DicingChamberBias_Power.Out.Setpoint', setpoint);
    setBiasscreen(!biasscreen)
}


useEffect(() => {
setSetpoint(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.Setpoint").currentValue)
// setPosition(gasBoxState?.find(o => o.name === "DicingChamberThrottleValveTarget_Position").currentValue)
}, [gasBoxState]);


  return (<>
    
    <div className="text-black">
     
      <div className="rounded-full flex p-2 text-center bg-flip-blue mt-5"> 
        <div className={`flex-1 rounded-l-full p-1 bg-flip-label ${gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==true && 'bg-flip-label-active'} -mr-3  text-sm transition-all`}>ON</div>
        <div className="relative rounded-full  flex-1 bg-flip-btn cursor-pointer" onClick={()=>modeFunction(gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue)}>
          <div className={`rounded-full h-5 w-5 cursor-pointer bg-cyan-400 border-2 shadow-inner shadow-gray-500 absolute top-1 transition-all ${gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==true ? "left-1" : "right-1"}`}></div> 
        </div>
        <div className={`flex-1 rounded-r-full p-1 bg-flip-label ${gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==false && 'bg-flip-label-active  '} -ml-3 text-sm transition-all`}>OFF</div>
      </div>
      <div className="flex w-full mt-5 font-bold">
          <div className="flex-1 font-bold text-gray-600">Setpoint </div>
          <div className="dysing-input rounded relative flex">
            <input type="text" value={setpoint} onChange={(e)=>setSetpoint(e.target.value)} className="bg-transparent flex-1 w-40  p-1 px-2"/>
            {/* <div className="text-right p-1 px-2 w-12">Torr</div> */}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mt-5">
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600" onClick={()=>setBiasscreen(!biasscreen)}>CANCEL</button>
          <button className="dysing-motorbtn hover:bg-white shadow-md shadow-gray-400 !w-full text-sm  mt-5 font-bold py-3 rounded-lg  !text-gray-600"
          onClick={()=>SetpointFunction()}>SET</button>
        </div>
    </div>

  </>);
}